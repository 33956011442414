// noinspection JSFileReferences

import "./bootstrap";
import "../css/app.css";
import "flowbite";

import {createApp, h} from "vue";
import {createInertiaApp} from "@inertiajs/vue3";
import {resolvePageComponent} from "laravel-vite-plugin/inertia-helpers";
import {ZiggyVue} from "../../vendor/tightenco/ziggy/dist/vue.m";
import {defaultConfig as FKdc, plugin as FKp} from "@formkit/vue";
import configFrmkit from "@/formkit.config.js";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
// import Bugsnag from '@bugsnag/js';
// import BugsnagPluginVue from '@bugsnag/plugin-vue';
// import BugsnagPerformance from '@bugsnag/browser-performance';

import Particles from "@tsparticles/vue3";
import { loadFull } from "tsparticles";
//import { Engine } from "@tsparticles/engine";

// const bugsnagKey = import.meta.env.VITE_BUGSNAG_FRONT_KEY;

// Bugsnag.start({
//     apiKey: bugsnagKey,
//     plugins: [new BugsnagPluginVue()],
//     enabledReleaseStages: ["production", "testing"],
// });

// BugsnagPerformance.start({apiKey: bugsnagKey});
// const bugsnagVue = Bugsnag.getPlugin('vue');

// import AuthenticatedLayout from "@/Layouts/AuthenticatedLayout.vue";

const appName = import.meta.env.VITE_APP_NAME || "Laravel";

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue"),
        ),
    // resolve: name => {
    //     const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
    //     let page = pages[`./Pages/${name}.vue`]
    //     page.default.layout = page.default.layout || AuthenticatedLayout
    //     return page
    // },
    setup({el, App, props, plugin}) {
        return createApp({render: () => h(App, props)})
            .use(plugin)
            .use(FKp, FKdc(configFrmkit))
            .use(ZiggyVue, Ziggy)
            .use(VueSweetalert2)
            // .use(bugsnagVue)
            .use(Particles, {
                init: async (engine) => {
                    await loadFull(engine);
                }
            })
            .mount(el);
    },
    progress: {
        color: "#e78b2f",
        showSpinner: true,
    },
});
